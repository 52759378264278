import React from 'react';
import Layout from '@components/Layout';

import Development from '@components/Development';

const AppEnglish = () => {
  return (
    <Layout english title="Arkos Digital - Development of personalized digital products" description="Arkos Digital is a company focused on problem solving. Our customer's satisfaction with the results is just a consequence. Do you have a project in mind?">
      <Development />
    </Layout>
  );
};

export default AppEnglish;
